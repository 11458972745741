.sorry{
    text-transform: uppercase !important;
    font-weight: 700 !important; 
    font-size: 35px !important;
    margin: 0 0 30px !important;
    font-size: 35px !important;
    font-family: 'Roboto', sans-serif !important;
}

.backgroundImage404{
    background-image: url(./Components/Assets/Flatbed20.jpg) !important;
    object-fit:scale-down !important;
}


.custom-col-contact{
    width: auto !important;
}
.specialh1{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 25px;
    font-size: 35px;
    font-weight: 900;
    position: relative;
    word-wrap: break-word;
    text-transform: uppercase;
    display: inline-block;
}
.specialh1::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Thickness of the underline */
    background-color: #ff0000; /* Color of the underline */
    bottom: -5px; /* Adjust this value to control the gap */
    left: 0;
  }

/* @media (max-width: 1025px){
    .custom-col-contact{
        display: flex;
        align-items: center !important;
    }
    .teaser{
        display: flex;
        align-items: center;
        justify-content: center;
    }
} */

.special-padding{
    padding-top: 50px;
    padding-bottom: 50px;
}
@media (max-width: 1199px){
    .custom-col-contact{
        display: flex;
        align-items: center !important;
    }
    .teaser{
        display: flex;

        align-items: center;
        justify-content: center;
    }
    .teaser-column{
        flex-direction: column;
    }
}
.custom-homefeatuer-cont{
  flex-direction: row;
  /* display: flex; */
}


