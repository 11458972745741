.details h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  font-size: 35px;
  letter-spacing: -0.3px;
  line-height: 1.2em;
  font-family: "Poppins", sans-serif;
}
h2 {
  font-weight: 400 !important;
}

.carousel-container {
  position: relative;
  height: 50%;
  max-height: 50%;
  max-width: 100%;
  margin: 0 auto;
  text-transform: uppercase;
  /* padding: 10px; */
}

.slider-img {
  height: 100vh !important;
  object-fit: cover;
  /* border-radius: 30px; */
  position: relative;
  z-index: 0;
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 1;
  /* border-radius: 30px; */
}
.text-box {
  /* min-width: 150px; */
  width: 850px;
  min-height: 300px;
  border-radius: 30px;
  color: white;

  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  z-index: 2;
}

.text-box-left {
  left: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: end;
  transform: translateY(-50%);
}

/* Center alignment for second slide */
.text-box-center {
  left: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  transform: translate(-50%, -50%);
}
.text-box-right {
  left: 40%;
  display: flex;
  text-align: end;
  align-items: flex-end;
  justify-content: end;
  transform: translateY(-50%);
}

.text-box h3,
.text-box .txt-h3 {
  font-family: roboto, sans-serif;
  font-size: 60px !important;
  font-weight: 300 !important;
  /* line-height: 24px !important; */
}
.text-box .txt-h3-2 {
  font-weight: 800 !important;
  color: #ff0000 !important;
}
/* .text-box h2 {
  color: white !important;
  font-size: 18px;
  font-weight: 700;
  line-height:5px;
  font-family: Roboto, ;
} */

.slide-image {
  width: 100%;
  height: 100%;
  /* height: 20%; */
  object-fit: cover;
}

.slick-slider {
  width: 100%;
  height: 100%;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.text-box h2 {
  color: white !important;
  font-size: 18px;
  font-weight: 700;
  font-family: Roboto;
}

.slick-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.banner-book-now {
  border: 1px solid #999797;
  padding: 12px;
  inline-size: 160px;
  border-radius: 50px;
  text-align: center;
  color: white;
  background-color: rgb(187, 154, 101);
}
.banner-book-now2 {
  border: 1px solid #999797;
  padding: 12px;
  inline-size: 160px;
  border-radius: 50px;
  text-align: center;
}

.book-now-circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  rotate: 310deg;
  position: absolute;
  top: 1px;
  left: 140px;
  color: rgb(187, 154, 101);
  background-color: white;
}

.booknow-circle-cont {
  /* display: flex; */
  position: relative;
}

.moreAbout2 {
  display: none;
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next {
  display: none !important;
}
/* Responsive Styles */
@media (max-width: 1440px) {
  .slider-img {
    max-height: 100vh;
  }
  .text-box {
    max-width: 720px;
    padding: 30px;
  }
}

@media (max-width: 1024px) {
  .text-box {
    max-width: 600px;
    padding: 25px;
  }

  .text-box h3,
  /* .text-box .txt-h3 {
    font-size: 45px;
  } */

  .text-box h2 {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .text-box {
    max-width: 450px;
    padding: 20px;
    left: 50%; /* Center horizontally */
    bottom: 5%;
    top: auto; /* Reset top to auto */
    transform: translateX(-50%); /* Only translate on X-axis */
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .txt-h3 {
    padding-top: 15px;
  }
  .text-box h3,
  .text-box .txt-h3 {
    font-size: 30px !important;
  }
  .txt-h3-2 {
    line-height: 28px;
  }

  .moreAbout2 {
    background-color: rgb(187, 154, 101);
    display: none;
    border-radius: 30px;
  }
  .slider-img {
    max-height: 70vh;
  }
  .carousel-indicators {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .slider-img {
    max-height: 60vh;
  }
  .moreAbout2 {
    display: block;
  }
  .moreAbout {
    display: none;
  }
  .carousel-indicators {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .text-box {
    max-width: 300px;
    min-height: 180px;
    padding: 15px;
    left: 50%;
    bottom: 5%; /* Keep it 5% from the bottom */
    top: auto; /* Reset top to auto */
    transform: translateX(-50%); /* Center horizontally */
    justify-content: center;
    align-items: center;
  }

  .text-box h3 {
    font-size: 28px !important; /* Reduce font size for smaller screens */
    text-align: center; /* Ensure text is centered */
  }

  .text-box h2 {
    font-size: 12px;
    display: none; /* Optionally hide if it's too small */
  }

  .slider-img {
    max-height: 60vh;
  }
}

@media (max-width: 320px) {
  .text-box {
    max-width: 280px;
    min-height: 160px;
    padding: 10px;
    left: 50%;
    bottom: 5%;
    top: auto;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
  }

  .text-box h3 {
    font-size: 24px !important; /* Further reduce font size */
    text-align: center;
  }

  .text-box h2 {
    font-size: 10px;
    display: none;
  }

  .slider-img {
    max-height: 55vh;
  }
}
