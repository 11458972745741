
.checkList-icon-cont{
    flex-direction: row; 
    /* gap: 10px;  */
    display: flex;
    align-items: center;
}

.detail-padding{
    padding-left: 10px !important;
}
.custom-col{
    width: auto !important;
    height: 100% !important;
}
.custom-col .homeAboutimgCont{
    height: 400px !important;
    display: flex;
    /* width: 500px !important; */
}
.custom-col .homeAboutimgCont .homeabout-img{
    height: 100%;
    width: 100%;
}
 @media(min-width:1200px){
    .custom-col .homeAboutimgCont{
        height: 400px !important;
        width: 500px !important;
    }
 }
/* .ls{
    width: max-content;
} */
@media(max-width:1200px){
    .custom-homeabout-row{
        display: flex  !important;
        flex-direction: column !important;
    }
}

@media(min-width:1024px){
    .homeAboutimgCont{
        width: 100% !important;
    }
    .homeAboutimgCont img{
        width: 100%;
        height: 100%;
    }
}