h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  word-break: break-word !important;
  font-weight: 300 !important;
  /* margin-top: 1.5em !important;
    margin-bottom: 1em !important; */
}
.contact-header {
  font-weight: 300 !important;
}
b,
strong {
  font-weight: 700 !important;
}
.breadcrumb li a {
  font-weight: 500 !important;
  text-decoration: none;
}
.media {
  display: flex !important;
  flex-direction: row !important;
}
.media-body {
  /* padding-left: 20px; */
}

.breadcrumb {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-teaser{
  gap:5px;
}
.ls .contactdecp{
  padding-left: 0.6rem;
  padding-right: 0.6rem;

}

.contactdecp p{
  text-align: justify;
}

@media (max-width: 992px) {
  .container, .container-sm {
      max-width: 100% !important;
  }
}

/* Style for the section containing the Google Map */
.container{
  padding: 50px;
}
.map-container {
  display: flex;
  justify-content: center; /* Center the map */
  align-items: center;

}

.map-iframe {
  width: 100%;   
  height: 400px !important;
  border: 0; /* Remove the default border */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); Add a subtle shadow */
  border-radius: 10px; /* Add rounded corners */
}

@media (max-width: 768px) {
  .map-iframe {
    height: 350px; 
  }
}

@media (max-width: 576px) {
  .map-iframe {
    height: 300px; /* Further adjust height for very small screens */
  }
}


.form-group .error-text{
  color: red; 
}