.regular a{
    text-decoration: none !important;
}
.custom-homeservice-col{
    /* text-align: left !important; */
    
}
.regular{
    /* margin-top: 30px; */
}

.eee{
    display: flex;
    justify-content: center;
}
.title-cont{
    margin-top: 10px;
    
}
.serviceDescription{
    padding-bottom: 20px;
}
.carImgCont{
    width: 100%;
    height: 300px;

}
.carImgCont img{
    height: 100%;
    width: 100%;
}
.topmargin_30{
 padding-bottom: 50px;
}
@media(max-width:1199px){
    .custom-homeservice-col{
        display: flex;
        flex-direction: column;
    }
}
@media(max-width:769px){
    .carImgCont{
        /* width: 200px;
        height: 200px; */
    }
    .carImgCont img{
        width: 100%;
        height: 100%;
    }
    .with_padding{
        padding: 0px;
    }
    .homeservcie-card-cont{
        padding-bottom: 30px;
    }
}

@media(max-width:426px){
    .carImgCont{
        width: 100%;
        /* height: 100%; */
    }
    .carImgCont img{
        width: 100%;
        height: 100%;
    }
}